<template>
  <div class="notice-field">
    <p
      v-for="(line, index) in input.lines"
      :key="index"
      class="form-notice-text"
    >
      {{ lang[activeLang][line] }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FormNotice',
  props: {
    formData: {
      type: Object,
      default: new Object()
    },
    activeLang: {
      type: String,
      default: 'English'
    },
    input: {
      type: Object,
      required: true
    },
    inputKey: {
      type: String,
      default: null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.notice-field {
  width: 100%;
  margin-top: 1em;
  background-color: #f3f3f3;
  border: 2px solid #d4d6de;
  color: rgb(102, 102, 102);
  padding: 5px 1em;
  border-radius: 5px;
  height: 100%;
  text-align: left;
}
</style>
