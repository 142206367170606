<template>
  <fade-in>
    <div class="header-container">
      <h1>{{ lang[activeLang]['REQUEST LEGAL ASSISTANCE'] }}</h1>
      <p>
        {{ lang[activeLang]['To request legal assistance online'] }}
        <span v-if="activeLang === 'English'">
          <a href="https://www.aclu-sdic.org/en/request-legal-assistance">how the ACLU of San Diego & Imperial Counties accepts cases</a>
        </span>
        <span v-if="activeLang === 'Español'">
          <a href="https://www.aclu-sdic.org/en/request-legal-assistance">cómo la ACLU de los Condados de San Diego e Imperial acepta casos</a>
        </span>
        {{ lang[activeLang]['before filling out this form'] }}
      </p>
      <p>
        <em>* </em> {{ lang[activeLang]['Indicates the required fields.'] }}
      </p>
    </div>
  </fade-in>
</template>

<script>
import FadeIn from './FadeIn.vue'

export default {
  name: 'FormFileHeader',
  components: {
    FadeIn
  },
  props: {
    activeLang: {
      type: String,
      default: 'English'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  text-align: left;
}

a {
  text-decoration: none;
  color: #0055aa;
}
.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.spacer {
  width: 2%;
}

.header-logo {
  width: 64px;
  height: 64px;
  color: black;
}

h1 {
  text-align: left;
  font-size: 32px;
}

p {
  text-align: left;
}

.underlined-strong {
  text-decoration: underline;
}

</style>
