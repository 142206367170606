<template>
  <div class="container-main">
    <div
      v-if="debugging"
      class="debugging-container"
    >
      <pre v-if="JSONData">
      <code>
        {{ JSONData }}
      </code>
      </pre>
      <h1
        style="color: rgb(214, 88, 88);"
      >
        DEBUGGING MODE ENABLED.
      </h1>
      <div
        class="debug-button"
        @click="clearFormData"
      >
        <label class="submit-button-text">
          CLEAR LOCAL DATA
        </label>
      </div>
      <div
        class="debug-button"
        @click="submitForm"
      >
        <label class="submit-button-text">
          TEST SUBMIT
        </label>
      </div>
    </div>
    <FormCompleteHeader
      v-if="submitted === true"
      id="FormCompleteHeader"
      :active-lang="activeLang"
    />
    <FormHomeHeader
      v-else-if="getFormData('incident_in_state') != 'Yes' && survey != true"
      :active-lang="activeLang"
    />
    <FormFileHeader
      v-else-if="getFormData('incident_in_state') === 'Yes' && survey != true"
      id="FormFileHeader"
      :active-lang="activeLang"
    />
    <FormSurveyHeader
      v-else-if="survey === true"
      id="FormSurveyHeader"
      :active-lang="activeLang"
    />
    <label
      v-if="formError === true"
      id="error-notice"
    >
      {{ lang[activeLang][['Please review the highlighted fields.']] }}</label>
    <Loading v-if="loading" />
    <div v-if="submitted != true && !loading">
      <slot v-for="(formSection, index) in formStructure">
        <form-section
          v-if="
            typeof formSection.shouldShow === 'undefined' ||
              (typeof formSection.shouldShow != 'undefined' &&
                formSection.shouldShow() === true)
          "
          :id="index"
          :key="index"
          :title="index"
          :form-section="formSection"
          :active-lang="activeLang"
          :form-data="formData"
          @formUpdate="formFieldChanged"
        />
      </slot>
      <div
        v-if="(getFormData('incident_in_state') === 'Yes' && this.survey != true)"
      >
        <button
          id="submit_button"
          class="submit-button"
          @click="submitForm"
        >
          {{ lang[activeLang]['Submit Request'] }}
        </button>
      </div>
      <div
        v-if="this.survey === true"
      >
        <button
          id="submit_button2"
          class="submit-button"
          @click="submitForm2"
        >
          {{ lang[activeLang]['Submit Survey'] }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from './FormSection.vue'
import FormHomeHeader from './FormHomeHeader.vue'
import FormFileHeader from './FormFileHeader.vue'
import FormSurveyHeader from './FormSurveyHeader.vue'
import Loading from './Loading.vue'
import FormCompleteHeader from './FormCompleteHeader.vue'
import states from '../states.js'
//import counties from '../counties.js'
import formRequest from "../api.js"

function isValidEmail(email) {
  // Regular expression taken from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
  if (!re.test(email)) {
    return false
  }
  return true
}

function isValidPhoneNumber(number) {
  const re = /^(\+\d+)?\s*\(?\d+\)?[\s.-]?\d*[\s.-]?\d*$/
  if (!re.test(number)) {
    return false
  }
  return true
}

function isValidZipCode(zipCode) {
  const re = /^\d{5}(?:[-\s]\d{4})?$/
  if (!re.test(zipCode)) {
    return false
  }
  return true
}

export default {
  name: 'InquiryForm',
  components: {
    FormSection,
    FormHomeHeader,
    FormCompleteHeader,
    Loading,
    FormFileHeader,
    FormSurveyHeader
  },
  props: {
    activeLang: {
      type: String,
      default: localStorage.activeLang || 'English'
    }
  },
  data() {
    return {
      formData: JSON.parse(localStorage.formData || '{"filer_state":"CA"}'),
      erroredFields: [],
      JSONData: null,
      submitted: false,
      survey: false,
      formError: false,
      debugging: false,
      loading: true,
      formStructure: {}
    }
  },
  async beforeMount() {
    this.loading = true
    try {
      await formRequest.requestFormData()
      this.loading = false
    } catch (error) {
      // console.log(error)
    }
  },
  mounted() {

    // Build form inputs here.



    /* 
      OPTIONAL Survey
      ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    */
    this.addSection('OPTIONAL SURVEY', {
      shouldShow: () => {
        return this.survey === true
      }
    })
    
    this.addToRow('OPTIONAL SURVEY', 0, 'optional_survey', {
      lines: ['optional_survey'],
      type: 'notice'
    })
    
    this.addToRow('OPTIONAL SURVEY', 1, 'survey_ethnicity', {
      title: 'Ethnic Racial Background',
      type: 'checkbox',
      options: ['American Indian / Indigenous or Alaska Native', 'Asian', 'Black or African American', 'Hispanic or Latino/Latinx', 'Arab, Middle Eastern, or North African', 'South Asian', 'Native Hawaiian or Other Pacific Islander', 'White', 'Not listed'],
      fullwidth: true
    })
    //options: formRequest.apiData.get_ethnicities.options,

    this.addToRow('OPTIONAL SURVEY', 2, 'survey_ethnicity_typed', {
      title: 'Please Specify Your Ethnic/Racial Background',
      type: 'text',
      shouldShow: () => {
        let data = this.getFormData("survey_ethnicity")
        if (!data) return false

        return data.includes && data.includes('Not listed')
      },
      fullwidth: true
    })

    this.addToRow('OPTIONAL SURVEY', 3, 'survey_zip', {
      title: 'Zip Code',
      type: 'text',
      required: false,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return 'Please enter a valid zip code.'
        }
      },
    })

    this.addToRow('OPTIONAL SURVEY', 3, 'survey_gender', {
      title: 'Gender',
      type: 'text',
      required: false,
    })

    this.addToRow('OPTIONAL SURVEY', 4, 'survey_sexorientation', {
      title: 'Sexual Orientation',
      type: 'text',
      required: false,
    })



    /* 
      INCIDENT LOCATION 
      ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    */
    this.addSection('THE LOCATION OF THIS INCIDENT', {
      shouldShow: () => {
        return this.survey === false
      }
    })

    this.addToRow('THE LOCATION OF THIS INCIDENT', 0, 'location_notice', {
      lines: ['location_notice'],
      type: 'notice'
    })

    this.addToRow('THE LOCATION OF THIS INCIDENT', 1, 'incident_in_state', {
      title: 'Did this incident happen in San Diego?',
      type: 'select',
      options: ['Yes', 'No'],
      required: true,
      fullwidth: true
    })

    this.addToRow('THE LOCATION OF THIS INCIDENT', 2, 'affiliate_notice', {
      lines: ['affiliate_notice'],
      type: 'notice',
      shouldShow: () => {
        return this.getFormData('incident_in_state') === 'No'
      },
    })

    this.addToRow('THE LOCATION OF THIS INCIDENT', 3, 'visit_affiliates', {
      title: 'Visit ACLU affiliates',
      type: 'button',
      url: 'https://www.aclu.org/about/affiliates?redirect=affiliates',
      shouldShow: () => {
        return this.getFormData('incident_in_state') === 'No'
      }
    })



    /* 
      CONTACT INFORMATION
      ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    */
    this.addSection('CONTACT INFORMATION', {
      shouldShow: () => {
        return (this.getFormData('incident_in_state') === 'Yes' && this.survey != true)
      }
    })

    this.addToRow('CONTACT INFORMATION', 0, 'by_checking_this_box', {
      type: 'checkbox',
      options: ['by_checking_this_box'],
      required: false,
      fullwidth: true,
    })

    //this.addToRow('CONTACT INFORMATION', 1, 'filer_salutation', {
    //  title: 'Title',
    //  type: 'select',
    //  options: ['Dr.', 'Miss', 'Mr.', 'Mrs.', 'Ms.', 'Rev.', 'Prof.', 'Mx.'],
    //  fullwidth: true,
    //  shouldShow: () => {
    //    return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
    //  }
    //})

    this.addToRow('CONTACT INFORMATION', 1, 'filer_firstname', {
      title: 'First Name',
      type: 'text',
      required: true,
      shouldShow: () => {
        return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
      }
    })

    this.addToRow('CONTACT INFORMATION', 1, 'filer_lastname', {
      title: 'Last Name',
      type: 'text',
      required: true,
      shouldShow: () => {
        return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
      }
    })

    this.addToRow('CONTACT INFORMATION', 2, 'filer_gender', {
      title: 'Gender',
      type: 'select',
      required: true,
      options: formRequest.apiData.get_genders.options, 
      shouldShow: () => {
        return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
      }
    })

    this.addToRow('CONTACT INFORMATION', 2, 'filer_pronouns', {
      title: 'Pronouns',
      type: 'select',
      required: true,
      options: formRequest.apiData.get_pronouns.options, 
      shouldShow: () => {
        return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
      }
    })

    this.addToRow('CONTACT INFORMATION', 3, 'filer_ethnicity', {
      title: 'Racial/Ethnic Background',
      type: 'select',
      required: true,
      options: formRequest.apiData.get_ethnicities.options, 
      shouldShow: () => {
        return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
      }
    })

    this.addToRow('CONTACT INFORMATION', 3, 'filer_other_ethnicity', {
      title: 'Other Ethnicity',
      type: 'text',
      shouldShow: () => {
        return ((this.getFormData("by_checking_this_box") == 'null' || this.getFormData("by_checking_this_box")  == '') && (this.getFormData("filer_ethnicity") === 'Other'))
      }
    })

    //this.addToRow('CONTACT INFORMATION', 4, 'filer_agency', {
    //  title: 'Organization',
    //  type: 'text',
    //  required: false,
    //  shouldShow: () => {
    //    return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
    //  }
    //})

    this.addToRow('CONTACT INFORMATION', 4, 'filer_address', {
      title: 'Street Address',
      type: 'text',
      required: true,
      shouldShow: () => {
        return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
      }
    })

    this.addToRow('CONTACT INFORMATION', 4, 'filer_city', {
      title: 'City',
      type: 'text',
      required: true,
      shouldShow: () => {
        return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
      }
    })

    this.addToRow('CONTACT INFORMATION', 5, 'filer_state', {
      title: 'State',
      type: 'select',
      options: states,
      required: true,
      currentVal: 'CA',
      shouldShow: () => {
        return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
      }
    })

    this.addToRow('CONTACT INFORMATION', 5, 'filer_zip', {
      title: 'Zip',
      type: 'text',
      required: true,
      isValid(zipCode) {
        if (!isValidZipCode(zipCode)) {
          return 'Please enter a valid zip code.'
        }
      },
      shouldShow: () => {
        return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
      }
    })

    this.addToRow('CONTACT INFORMATION', 6, 'filer_phone', {
      title: 'Phone',
      type: 'text',
      required: true,
      isValid(phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber)) {
          return 'Please enter a valid phone number.'
        }
      },
      shouldShow: () => {
        return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
      }
    })

    this.addToRow('CONTACT INFORMATION', 6, 'filer_email', {
      title: 'Email',
      type: 'text',
      required: true,
      isValid(email) {
        if (!isValidEmail(email)) {
          return 'Please enter a valid email address.'
        }
      },
      shouldShow: () => {
        return (this.getFormData("by_checking_this_box") == null || this.getFormData("by_checking_this_box") == '')
      }
    })
  
    

    /* 
      DETAILS OF COMPLAINT
      ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    */
    this.addSection('COMPLAINT DETAILS', {
      shouldShow: () => {
        return (this.getFormData('incident_in_state') == 'Yes' && this.survey != true)
      }
    })

    this.addToRow('COMPLAINT DETAILS', 1, 'incident_notice', {
      lines: ['incident_notice'],
      type: 'notice'
    })

    this.addToRow('COMPLAINT DETAILS', 2, 'incident_reason', {
      title:
        'WHAT IS THE PROBLEM',
      type: 'textarea',
      charlimit: 5000,
      required: true
    })

    this.addToRow('COMPLAINT DETAILS', 3, 'incident_attorney', {
      title:
        'DO YOU HAVE AN ATTORNEY',
      type: 'textarea',
      charlimit: 5000,
      required: true
    })

    this.addToRow('COMPLAINT DETAILS', 4, 'incident_resolution', {
      title:
        'WHAT KIND OF ASSISTANCE',
      type: 'textarea',
      charlimit: 5000,
      required: true
    })

    this.addToRow('COMPLAINT DETAILS', 5, 'incident_extras', {
      title:
        'If you have documents, recordings, or other materials',
      type: 'textarea',
      charlimit: 5000,
      required: true
    })



/* 
      DISCLAIMER AND NOTICE
      ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    */
    this.addSection('DISCLAIMER AND NOTICE', {
      shouldShow: () => {
        return (this.getFormData('incident_in_state') == 'Yes' && this.survey != true)
      }
    })

    this.addToRow('DISCLAIMER AND NOTICE', 1, 'disclaimer_notice1', {
      lines: ['disclaimer_notice1'],
      type: 'notice'
    })

    this.addToRow('DISCLAIMER AND NOTICE', 2, 'disclaimer_notice2', {
      lines: ['disclaimer_notice2'],
      type: 'notice'
    })

    this.addToRow('DISCLAIMER AND NOTICE', 2, 'disclaimer_notice3', {
      lines: ['disclaimer_notice3'],
      type: 'notice'
    })

    this.addToRow('DISCLAIMER AND NOTICE', 2, 'disclaimer_notice4', {
      lines: ['disclaimer_notice4'],
      type: 'notice'
    })


  },
  methods: {
    formFieldChanged(fieldID, val) {
      // Storing the values into local storage so data is maintained across page navigations.
      this.formData[fieldID] = val
      localStorage.formData = JSON.stringify(this.formData)
      if (fieldID == "incident_in_state") {
        let elmnt = document.getElementById("incident_in_state")
        elmnt.scrollIntoView()
      }
    },
    clearFormData() {
      delete localStorage.formData
    },
    async submitForm() {
      // Make sure the form is valid before properly submitting
      const isValid = this.isFormValid()
      if (!isValid) {
        this.formError = true
        // Loop over all erroredFields and find one that has an error.
        for (let i = 0; i < this.erroredFields.length; i++) {
          let input = this.erroredFields[i]
          if (!input.hasError) { 
            // Remove it from the array since it's no longer relevent
            this.erroredFields.splice(i, 1)
            continue
          }

          // Scroll to errored element for user to see
          let elmnt = document.getElementById(input.index)
          elmnt.scrollIntoView({block: "center"})
          break
        }
      } else {
        this.JSONData = JSON.stringify(this.formData, null, 4)
        const status = await formRequest.submitForm(this.JSONData)
        //console.log(status)
        if (status === 200) {
          //console.log(this.JSONData)
          this.submitted = true
          //this.survey = true
          this.formError = false      
        }
        // Do not delete stored data if debugging.
         if (!this.debugging && !this.survey) delete localStorage.formData
      }
      // This data will be sent via http request.
      //this.JSONData = JSON.stringify(this.formData, null, 4)
      //console.log(this.JSONData)
    },
    async submitForm2() {
      // Make sure the form is valid before properly submitting
      const isValid = this.isFormValid()
      if (!isValid) {
        this.formError = true
        // Loop over all erroredFields and find one that has an error.
        for (let i = 0; i < this.erroredFields.length; i++) {
          let input = this.erroredFields[i]
          if (!input.hasError) { 
            // Remove it from the array since it's no longer relevent
            this.erroredFields.splice(i, 1)
            continue
          }

          // Scroll to errored element for user to see
          let elmnt = document.getElementById(input.index)
          elmnt.scrollIntoView({block: "center"})
          break
        }
      } else {
        this.JSONData = JSON.stringify(this.formData, null, 4)
        const status = await formRequest.submitForm2(this.JSONData)
        //console.log(status)
        if (status === 200) {
          console.log(this.JSONData)
          this.submitted = true
          this.survey = false
          this.formError = false      
        }
        // Do not delete stored data if debugging.
         if (!this.debugging && !this.survey) delete localStorage.formData
      }
      // This data will be sent via http request.
      //this.JSONData = JSON.stringify(this.formData, null, 4)
      //console.log(this.JSONData)
    },
    addSection(title, sectionData) {
      this.formStructure[title] = sectionData || {}
    },
    addToRow(section, rowID, index, content) {
      content.section = section
      content.index = index
      // Add a content to field to the section if it does not exist already.
      this.formStructure[section].content =
        this.formStructure[section].content || []
      // Create a new row to the section content if it does not exist already.
      this.formStructure[section].content[rowID] =
        this.formStructure[section].content[rowID] || {}
      // Finally add the content to the section, content, row.
      this.formStructure[section].content[rowID][index] = content
    },
    getFormData(index) {
      // Alternative to indexing form data.
      return this.formData[index]
    },
    getInputError(index, input) {
      let showingSection = this.formStructure[input.section].shouldShow

      // If the section is not visible, it is not required to have a value.
      if (showingSection && !showingSection()) return false

      // If the input is not visible, it is not required to have a value.
      let showingInput = input.shouldShow
      if (showingInput && !showingInput()) {
        return false
      }

      // If the input is visible and is not required and is empty we're not gonna perform any valid checks.
      if (!input.required && !this.formData[index]) return false

      // If the input is required and is not inside the formData, then they skipped this input.
      if (!this.formData[index] || this.formData[index] === '') {
        input.hasError = true
        input.requiredError = true
        return true
      }

      // Finally perform valid checks such as email, zip or phone number.
      if (input.isValid) {
        let errorMsg = input.isValid(this.formData[input.index])
        if (errorMsg) {
          input.hasError = true
          input.errorMsg = errorMsg
          return true
        }
      }
    },
    isFormValid() {
      // TODO: Refactor formStructure with a better data structure.
      let isValid = true
      this.errorFields = []
      // eslint-disable-next-line no-unused-vars
      for (let [title, section] of Object.entries(this.formStructure)) {
        //console.log(title, section.content)
        for (let inputs of section.content) {
          console.log(inputs)
          
          /*
          for (let [inputIndex, input] of Object.entries(inputs)) {
            console.log("INPUT", input)
            // Reset any previous errors when performing the form valid check.
            input.hasError = false
            if (this.getInputError(inputIndex, input)) {
              isValid = false
              this.erroredFields.push(input);
            }
          }
          */

        }
      }
      return isValid
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lang-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#error-notice {
  width: 100%;
  color: rgb(214, 88, 88);
  text-align: left;
  font-size: 16px;
  padding-bottom: 20px;
  float: left;
  text-decoration: underline;
}

.debug-button {
  background-color: rgb(214, 88, 88);
  padding: 10px;
  margin: 20px 25%; 
  cursor: pointer;
  border-radius: 5px;
}

.container {
  display: flex;
  justify-content: center;
  align-content: flex-start;
}

.submit-button {
  background-color: #64b281;
  font-weight: 600;
  color: #fff;
  font-size: 15px;
  padding: 10px 0;
  border-radius: 5px;
  margin: 30px 25% 120px 25%;
  cursor: pointer;
  width: 250px;
}

.submit-button-text {
  pointer-events: none;
}

h1 {
  color: #9cbaf2;
}

label {
  color: #fff;
}
</style>
