const spanish = {
  //Header Home
  ['To request legal assistance online']:
    'Favor de leer aquí sobre', 
  ['before filling out this form']: 'antes de llenar este formulario. Por favor considere que no podemos responder inmediatamente. Lleva tiempo evaluar las solicitudes de asistencia legal, y con nuestros recursos limitados, muchas veces no podemos hacer las evaluaciones rápidamente. Es necesario llenar todas las casillas para poder procesar su solicitud.',


  //Header File Form
  ['PLEASE BE ADVISED:']: 'PLEASE BE ADVISED:',
  ["The purpose of this intake form is for the ACLU of San Diego ('ACLU-SDIC') to assess whether it can provide you with assistance or representation. Filling out this form does not create an attorney/client relationship between you and the ACLU-NJ. The ACLU-NJ does not represent you unless you and the ACLU-NJ have both signed a written retainer agreement."]:
    "The purpose of this intake form is for the ACLU of San Diego ('ACLU-SDIC') to assess whether it can provide you with assistance or representation. Filling out this form does not create an attorney/client relationship between you and the ACLU-NJ. The ACLU-NJ does not represent you unless you and the ACLU-NJ have both signed a written retainer agreement.",
  ['You are solely responsible']: 'You are solely responsible',
  ['for any and all statutes of limitations or other deadlines applicable to your specific situation. To protect your rights, please consult with a private attorney to determine deadlines applicable to your case.']:
    'for any and all statutes of limitations or other deadlines applicable to your specific situation. To protect your rights, please consult with a private attorney to determine deadlines applicable to your case.',
  ['Do not wait to hear from the ACLU-NJ before proceeding with your matter.']:
    'Do not wait to hear from the ACLU-NJ before proceeding with your matter.',
  ['Please be sure to read']: 'Please be sure to read',
  ['before filling out this form.']: 'before filling out this form.',
  ['There may be deadlines that could affect your lawsuit or grievance. If you are concerned about whether the time for bringing your complaint is about to pass, you should not rely on filing an ACLU complaint to protect you; you should consult with an attorney of your choice.']:
    'There may be deadlines that could affect your lawsuit or grievance. If you are concerned about whether the time for bringing your complaint is about to pass, you should not rely on filing an ACLU complaint to protect you; you should consult with an attorney of your choice.',
  ['San Diego Bar Lawyer Referral Service']:
    'San Diego Bar Lawyer Referral Service',
  ['San Diego Rio Grande Legal Aid']: 'San Diego Rio Grande Legal Aid',
  ['San Diego Access to Justice Commission']:
    'San Diego Access to Justice Commission',
  ['San Diego Law Help']: 'San Diego Law Help',
  ['(click on “find a lawyer”) or call 1-877-9TEXBAR (toll free)']:
    '(click on “find a lawyer”) or call 1-877-9TEXBAR (toll free)',
  ['or call 1-888-988-9996 (toll free)']: 'or call 1-888-988-9996 (toll free)',
  ['(click on “find legal assistance”) or call 800-204-2222 ext. 2155']:
    '(click on “find legal assistance”) or call 800-204-2222 ext. 2155',
  ['If you would like to request legal assistance, fill out the form below.']:
    'If you would like to request legal assistance, fill out the form below.',

  // Header Optional Survey
  ['OPTIONAL SURVEY']: "OPTIONAL SURVEY",

  // Header Form Complete
  ['WE HAVE RECEIVED YOUR COMPLAINT']: 'WE HAVE RECEIVED YOUR COMPLAINT',
  ['Unfortunately, we have very limited resources and we are unable to take most cases. If we are able to help you, we will be back in touch as soon as possible. You should continue to seek other counsel and be mindful that you have a limited period of time to file a lawsuit. Thank you for contacting us, and thank you for your patience.']:
    'Unfortunately, we have very limited resources and we are unable to take most cases. If we are able to help you, we will be back in touch as soon as possible. You should continue to seek other counsel and be mindful that you have a limited period of time to file a lawsuit. Thank you for contacting us, and thank you for your patience.',
  ['Return to ACLU New Jeresey']: 'Return to ACLU New Jeresey',
  ['THANK YOU FOR COMPLETING THE SURVEY']: 'THANK YOU FOR COMPLETING THE SURVEY',
  ['We appreciate the information.']: 'We appreciate the information.',
  //Misc
  ['Language']: 'Language',
  ['Submit Request']: 'Enviar Solicitud',
  ['Submit Survey']: 'Submit Survey',
  ['Visit ACLU affiliates']: 'Visitar filiales de la ACLU',
  ['Indicates the required fields.']: 'Indica la casilla que debe llenar.',
  ['This field is required.']: 'This field is required.',
  ['Please review the highlighted fields.']:
    'Please review the highlighted fields.',
  ['Select']: 'Select',

  // Titles
  ['ACLU San Diego and Imperial Counties Online Form To Request Legal Assistance']:
    'Formulario para Solicitar Asistencia Legal de la ACLU-SDIC',
  ['ACLU SAN DIEGO ONLINE FORM TO REQUEST LEGAL ASSISTANCE']:
    'FORMULARIO DE SOLICITUD DE ASISTENCIA LEGAL ACLU-SDIC',
  ['THE LOCATION OF THIS INCIDENT']: 'EL LUGAR DEL INCIDENTE',
  ['REQUEST FOR LEGAL ASSISTANCE']: 'REQUEST FOR LEGAL ASSISTANCE',
  ['CONTACT INFORMATION']: 'INFORMACIÓN DE CONTACTO',
  ['REQUEST LEGAL ASSISTANCE']:
    'CÓMO SOLICITAR ASISTENCIA LEGAL',
  ['MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)']:
    'MY COMPLAINT IS AGAINST THE FOLLOWING (OPTIONAL)',
  ['LAWSUIT INFORMATION (OPTIONAL)']: 'LAWSUIT INFORMATION (OPTIONAL)',
  ['DETAILS OF COMPLAINT']: 'DETAILS OF COMPLAINT',
  ['COMPLAINT DETAILS']: 'LOS DETALLES DEL INCIDENTE',
  ['DISCLAIMER AND NOTICE']: 'DESCARGO DE RESPONSABILIDAD Y AVISO',
  ['ATTORNEY INFORMATION (OPTIONAL)']: 'ATTORNEY INFORMATION (OPTIONAL)',
  ['AGREEMENT']: 'AGREEMENT',

  // Incident Location
  ['Did this incident happen in San Diego?']:
    '¿Ocurrió este incidente en el condado de San Diego o en el condado de Imperial?',
  ['Yes']: 'Sí',

  // Contact and Filer Information
  ['Are you filing for yourself or on behalf of someone else?']:
    'Are you filing for yourself or on behalf of someone else?',
  ['Myself']: 'Myself',
  ['Someone else']: 'Someone else',
  ['The relationship between you and the affected individual']:
    'The relationship between you and the affected individual',
  ['First Name']: 'Nombre',
  ["Affected Individual's First Name"]: "Affected Individual's First Name",
  ["Affected Individual's Last Name"]: "Affected Individual's Last Name",
  ['Last Name']: 'Apellido',
  ['Gender']: 'Género',
  ['Street Address']: 'Domicilio',
  ['Address line']: 'Address line',
  ['Address line 2']: 'Address line 2',
  ['City']: 'Ciudad',
  ['State']: 'Estado',
  ['Zip']: 'Código postal',
  ['Phone']: 'Número de teléfono',
  ['Email']: 'Correo electrónico',
  ['Title']: 'Title',
  ['If a prisoner, prison name?']: 'If a prisoner, prison name?',
  ['Prisoner Number']: 'Prisoner Number',
  ['Racial/Ethnic Background']: 'Raza/Grupo étnico',
  ['Pronouns']: 'Pronombres',

  // gender, pronouns, ethnicity
  ['Man']: 'Hombre',
  ['Woman']: 'Mujer',
  ['Nonbinary']: 'No binario',
  ['Other']: 'Otro',
  ['Prefer Not to Say']: 'Prefiero no decir',
  ['She/her/hers']: 'Ella/de ella',
  ['He/him/his']: 'El/de él',
  ['They/them/theirs']: 'Elle/de elle',
  ['Not Specified']: 'Sin especificar',
  ['Something Else']: 'Algo más',
  ['I do not wish to state']: 'Prefiero no responder',
  ['African American/Black']: 'Afroamericano',
  ['Asian American']: 'Asiático-Americano',
  ['Pacific Islander']: 'De las Islas del Pacífico',
  ['Native American']: 'Nativo Americano',
  ['Middle Eastern']: 'Del Medio Oriente',
  ['White/Caucasian']: 'Blanco/Caucásico',
  ['Latino or Hispanic']: 'Latino or Hispano',
  ['Multiracial']: 'Multirracial',

  // Complaint Against
  ['Agency']: 'Agency',
  ['May we contact this person or agency?']:
    'May we contact this person or agency?',

  // ATTORNEY INFORMATION (OPTIONAL)
  ['Are you represented by a lawyer in this matter?']:
    'Are you represented by a lawyer in this matter?',

  // Reported
  ['Have you reported this incident to any other agency?']:
    'Have you reported this incident to any other agency?',
  ['If yes, please list all the agency names.']:
    'If yes, please list all the agency names.',

  // Incident Information
  ['Date of Incident']: 'Date of Incident',
  ['County of Occurrence']: 'County of Occurrence',
  ['Please describe IN DETAIL, all the events that led you to file this complaint.']:
    'Please describe IN DETAIL, all the events that led you to file this complaint.',
  ['State clearly what you would like the ACLU of San Diego to do for you.']:
    'State clearly what you would like the ACLU of San Diego to do for you.',

  // Field Errors
  ['Please enter a valid email address.']:
    'Please enter a valid email address.',
  ['Please enter a valid phone number.']: 'Please enter a valid phone number.',
  ['Please enter a valid zip code.']: 'Please enter a valid zip code.',

  // Notices
  ['by_checking_this_box']:
    'Quisiera llenar esta solicitud anónimamente.',
  ['location_notice']:
    'Por favor, díganos más información sobre la ubicación de este incidente. La ACLU de los Condados de San Diego e Imperial solo acepta casos que se originan en el condado de San Diego o en el condado de Imperial.',
    ['optional_survey']:
    'The information requested below is optional.',
  ['affiliate_notice']:
    'Si este incidente no ocurrió en el condado de San Diego o en el condado de Imperial, la ACLU-SDIC no podrá ayudarle. Haga clic en el botón abajo para ser dirigido a la filial correcta de la ACLU.',
  ['contact_info_notice']:
    'Contact Information of the person affected by the incident',
  ['background_notice']:
    'The following Ethnic/Racial Background information is optional. This is used for internal purposes only to provide the ACLU of San Diego demographic information about the communities it serves throughout San Diego.',
  ['complaint_against_notice']:
    'Please enter information about the individual, agency, and/or organization your complaint is against.',
  ['filer_contact_info_notice']:
    'Contact Information of the person who is filing on behalf of the affected individual',
  ['incident_notice']:
    'Describa brevemente los detalles del incidente que le ha llevado a presentar una solicitud ante nuestra oficina. Incluya fechas, lugares y nombres de las personas directamente involucradas. Indique en su resumen si tiene o no documentación de apoyo. Si tiene un enlace a cualquier video relevante, incluya la dirección URL en su resumen. No envíe ninguna documentación original junto con este formulario.',
  ['witness_notice']:
    'If you have a witness to support your claims, please enter their information here.',
  ['attorney_notice']:
    'If you are represented by an attorney on this matter, please provide the information here.',
  ['lawsuit_notice']:
    'If a criminal or civil lawsuit has been filed against you or on your behalf regarding your complaint, please provide the information here.',
  ['disclaimer_notice1']:
    'Esta solicitud de asistencia legal no provee asesoramiento legal, y usted no debe fiarse de esta solicitud como consejo legal. No debe fiarse de la información que obtiene de esta página web y debe consultar con un abogado para que le asesore sobre su situación en particular. Tampoco podemos prometer que la información de esta página web sea completa, precisa ni actualizada.',
  ['disclaimer_notice2']:
    'Esta solitud de asistencia legal no es petición ni oferta por parte de la Unión Americana de Libertades Civiles (ACLU) ni de sus filiales por representarle a usted. No le podemos prometer que la información que usted proporcione lleve a ninguna acción específica de parte de la ACLU ni de sus filiales.',
  ['disclaimer_notice3']:
    'Si usted llena esta solicitud de asistencia legal, acepta que la ACLU-SDIC, la ACLU nacional o sus filiales, si están identificadas como parte de esta solicitud, o uno de sus socios de coalición, podrá usar la información que usted nos proporciona, siempre que no se incluya su nombre, dirección postal, correo electrónico o número de teléfono, para uno o más de los siguientes propósitos: (1) testimonio legislativo, (2) litigio, (3) comunicación con una agencia municipal, estatal o federal, o (4) contar su historia al público, incluyendo los medios de comunicación. Si la ACLU-SDIC, la ACLU nacional o sus afiliados, o uno de sus socios de coalición desea identificarle, nos pondremos en contacto con usted antes de hacerlo.',
  ['disclaimer_notice4']:
    'Mantendremos confidencial su nombre, dirección postal, número de teléfono y correo electrónico a menos que usted nos dé permiso para utilizarlos o que un tribunal nos ordene entregarlos (aunque intentaremos evitar cualquier divulgación).',
  ['agreement_notice1']:
    'By clicking the "Submit Request" button to indicate that the information you have provided is true and correct; that you understand that, by accepting this intake form, the ACLU of San Diego is not agreeing to represent you; and that you understand that the ACLU of San Diego is not responsible for ensuring that any statute of limitations requirement or other deadline is met in your case.',
  ['agreement_notice2']:
    'If you submit a complaint, you agree that the ACLU of San Diego or the national ACLU may use the information you give us, as long as we don’t include your name, address, email, or phone number. We will keep your name, address, telephone number, and email confidential unless you give us permission to use it or unless we are ordered to turn it over by a court (although we will attempt to prevent any disclosure).',
  ['WHAT IS THE PROBLEM']:
    '¿CUÁL FUE EL PROBLEMA? Sea breve, pero incluya detalles específicos, tales como cuándo y dónde ocurrió el problema. Incluya los nombres de todas las personas, empresas y/o agencias gubernamentales.',
  ['DO YOU HAVE AN ATTORNEY']:
    '¿TIENE USTED UN ABOGADO? Si lo tiene, indique el nombre del abogado, su correo electrónico y número de teléfono.',
  ['WHAT KIND OF ASSISTANCE']:
    '¿QUÉ TIPO DE ASISTENCIA BUSCA USTED DE LA ACLU?',
  ['If you have documents, recordings, or other materials']:
    'Si tiene documentos, grabaciones u otros materiales que crea que pueden ayudarnos a evaluar su caso, descríbalos aquí brevemente.',

// Checkbox options
  ['American Indian']:
    'American Indian / Indigenous or Alaska Native',
  ['Asian']:
    'Asian',
  ['Black']:
    'Black or African American',
  ['Hispanic']:
    'Hispanic or Latino/Latinx',
  ['Arab']:
    'Arab, Middle Eastern, or North African',
  ['South Asian']:
    'South Asian',
  ['Native Hawaiian']:
    'Native Hawaiian or Other Pacific Islander',
  ['White']:
    'White',
  ['Not listed']:
    'Not listed (please specify)'
}
  
export default spanish
