<template>
  <div class="button-field">
    <a :href="input.url">
      <div class="form-button">
        <label class="form-button-text">
          {{ lang[activeLang][input.title] || input.title }}
        </label>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'FormButton',
  props: {
    formData: {
      type: Object,
      default: new Object()
    },
    activeLang: {
      type: String,
      default: 'English'
    },
    input: {
      type: Object,
      required: true
    },
    inputKey: {
      type: String,
      default: null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button-field {
  width: 100%;
  margin-top: 1em;
}

@media screen and (min-width: 900px) {
  .button-field {
    width: 45%;
  }
}

a {
  text-decoration: none;
}

.form-button {
  background-color: #db4e54;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
}

.form-button-text {
  pointer-events: none;
}
</style>
