import { createApp } from 'vue'
import App from './App.vue'
import english from './translations/english.js'
import spanish from './translations/spanish.js'
const app = createApp(App)
app.config.globalProperties.lang = {
  ['English']: english,
  ['Español']: spanish
}

app.mount('#app')
