<template>
  <fade-in>
    <div
      id="FormCompleteHeader"
      class="header-container"
    >
      <h1>{{ lang[activeLang]['THANK YOU FOR COMPLETING THE SURVEY'] }}</h1>
      <p>
        {{
          lang[activeLang][
            'We appreciate the information.'
          ]
        }}
      </p>
      <FormButton
        :input="{
          title: 'Return to ACLU San Diego',
          url: 'https://www.aclu-sdic.org/en'
        }"
        :active-lang="activeLang"
      />
    </div>
  </fade-in>
</template>

<script>
import FadeIn from './FadeIn.vue'
import FormButton from './FormButton.vue'

export default {
  name: 'FormCompleteHeader',
  components: {
    FormButton,
    FadeIn
  },
  props: {
    activeLang: {
      type: String,
      default: 'English'
    }
  },
  mounted() {
    let elmnt = document.getElementById('FormCompleteHeader')
    elmnt.scrollIntoView()
    window.scrollTo(0,0)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.spacer {
  width: 2%;
}

.header-logo {
  width: 64px;
  height: 64px;
  color: black;
}

h1 {
  text-align: left;
  font-size: 32px;
}

p {
  text-align: left;
}

em {
  color: rgb(214, 88, 88);
}
</style>
